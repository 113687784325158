import api from "./api";
import BaseService from "./base.service";

export default class CurriculossService extends BaseService {
	constructor(page?: number | string) {
		if (page) {
			super(`usuarios/curriculos?page=${page}`);
		}
		else {
			super(`usuarios/curriculos?page=1`);
		}
	}

	async listCurriculos(state: string = "") {
		try {
			const response = await api({
				method: "GET",
				url: `${this.resource}${state !== "" ? `&uf=${state}`:""}`,
			});

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao listar ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}

	async getProfissoes() {
		try {
			const response = await api.get(`usuarios/profissoes/`);
			return response.data;
		} catch (err) {
			console.error(`erro ao buscar profissões :`, err);
			return [];
		}
	}

	async meuCurriculo() {
		try {
			const response = await api.get(`usuarios/meu-curriculo/`);
			return {
				...response.data,
				"method": "PUT"
			};
		} catch (err) {
			console.error(`erro ao buscar currículo :`, err);
			return {
				"profissao": 0,
				"texto": "",
				"mudancas": 0,
				"exibir_curriculo": false,
				"arquivo": null,
				"method": "POST"
			};
		}
	}
	async updateCurriculo(data: any) {
		// console.log("DATA", data);
		// console.log("PDF FROM FORM DATA", data.arquivo.get("pdf"));

		const formData = data.arquivo;

		formData.append("profissao", data.profissao);
		formData.append("exibir_curriculo", data.exibir_curriculo);
		formData.append("mudancas", data.mudancas);
		formData.append("texto", data.texto);

		for (var pair of formData.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}
		// // console.log("RESOURCE => ", `${this.resource}/`);
		try {
			// const response = await api.put(`${this.resource}/`, data, multipartHeaders);
			const response = await api({
				method: data.method,
				url: `usuarios/meu-curriculo/edit/`,
				data: formData,
				headers: { "Content-Type": undefined },
			});

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao atualizar currículo :`, err);

			return {
				error: true,
			};
		}
	}
}
