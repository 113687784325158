import React, { createContext, useContext, ReactNode, useEffect } from "react";

import useAuth from "./hooks/useAuth";

interface UserContextState {
	authenticated: boolean;
	tenantData: any;
	userData: any;
	loading: boolean;
	handleLogin(email: string, password: string): Promise<object>;
	handleLogout(): void;
	isPlus(): boolean;
	alunoABA(): boolean;
	showPos(): boolean;
	showCurriculo(): boolean;
}

const UserContext = createContext({} as UserContextState);

interface ProviderProps {
	children: ReactNode;
}

const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
	const { authenticated, tenantData, userData, loading, handleLogin, handleLogout } = useAuth();

	const isPlus = () => {
		const groups = userData.grupos.split(",");
		if (
			groups.includes("Alunos") ||
			groups.includes("NMAAlunos") ||
			groups.includes("Mentores") ||
			groups.includes("Tutor") ||
			groups.includes("CIA30Dias") ||
			groups.includes("CIA_plus_empresa") ||
			groups.includes("NMA30dias")
		) {
			return true;
		}
		return false;
	};

	const alunoABA = () => {
		const groups = userData.grupos.split(",");
		if (groups.includes("CursoABA") || groups.includes("CursoABA2021") || groups.includes("CursoABA2022") || groups.includes("Mentores") || groups.includes("Tutor")) {
			return true;
		}
		return false;
	};

	const showPos = () => {
		// console.log(userData.grupos)
		const userGroups = userData.grupos.split(",");
		if (userGroups.includes("PosGraduacaoT1") ||
			userGroups.includes("PosGraduacaoT2") ||
			userGroups.includes("PosGraduacaoT3") ||
			userGroups.includes("PosGraduacaoT4") ||
			userGroups.includes("PosGraduacaoT1-24") ||
			userGroups.includes("PosGraduacaoP") ||
			userGroups.includes("Pos_ABA_T1_25") ||
			userGroups.includes("Pos_TEA") ||
			userGroups.includes("Tutor")) {
			// console.log("showPós")
			return true;
		}
		// console.log("notshowPós")
		return false;
	};

	const showCurriculo = () => {
		const userGroups = userData.grupos.split(",");
		if (
			// userGroups.includes("PosGraduacaoT1") ||
			// userGroups.includes("PosGraduacaoT2") ||
			// userGroups.includes("PosGraduacaoT3") ||
			// userGroups.includes("PosGraduacaoT4") ||
			// userGroups.includes("PosGraduacaoT1-24") ||
			// userGroups.includes("Mentor") ||
			userGroups.includes("Tutor")) {
			return true;
		}
		return false;
	}

	// useEffect(() => {
	// 	console.log(userData.grupos)
	// }, [userData])

	return (
		<UserContext.Provider
			value={{
				userData,
				tenantData,
				loading,
				authenticated,
				handleLogin,
				handleLogout,
				isPlus,
				showPos,
				alunoABA,
				showCurriculo,
			}}>
			{children}
		</UserContext.Provider>
	);
};

export const useUserContext = () => useContext(UserContext);

export { AuthProvider };
