import React, { useState, useEffect, ChangeEvent } from "react";
import { Box, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { ModalBase } from "../../Producer_bkp/ModalBase";
import StudentProfileService, { IStudentApiResponse } from "../../../../services/student.profile.service";
import api from "../../../../services/api";
import CurriculossService from "../../../../services/curriculos.service";
import { ICurriculo, ICurriculoPostVars, IProfissaoCurriculo } from "../../../../types/curriculos";
import { tr } from "date-fns/locale";

export interface Props {
	student: IStudentApiResponse;
	onUpdateAction?: () => void;
}

const UpdateUserCurriculumWrapper: React.FC<Props> = ({ student, onUpdateAction, ...props }: Props) => {
	const [modalOpen, setModalOpen] = useState(false);

	const [profissoes, setProfissoes] = useState<IProfissaoCurriculo[]>([]);

	const [loading, setLoading] = useState(false);

	const [curriculoVars, setCurriculoVars] = useState<ICurriculoPostVars>({
			"profissao": 0,
			"texto": "",
			"mudancas": 0,
			"exibir_curriculo": false,
			"arquivo": null,
			'method': "POST"
		}
	)

	function handleModalClose() {
		setModalOpen(false);
	}

	function handleModalOpen() {
		setModalOpen(true);
	}
	async function handleUpdateStudentProfile(event?: React.FormEvent<HTMLFormElement>) {
		if (event) {
			event.preventDefault();
		}
		try {
			const response = await api.post("/usuarios/trocar-senha/", {});
		} catch (error) {
			console.error(error);
		}

		handleModalClose();
	}

	const handleCurriculoVars = (field:string, value:any) => {
		let v = value;
		if(field === "exibir_curriculo") {
			v = value === 1 ? true : false
		}
		setCurriculoVars({...curriculoVars, [field]: v});
	}

	const getProfissoesAndCurriculo = async() => {
		const curriculoService = new CurriculossService();
		const profissaos = await curriculoService.getProfissoes();
		const meucurr:ICurriculo = await curriculoService.meuCurriculo();
		setProfissoes(profissaos);

		const profId: IProfissaoCurriculo = profissaos.find((p:IProfissaoCurriculo) => p.nome === meucurr.profissao)

		// console.log("PROFISSAO ---->", profId)

		const mudancas = {
			"Não": 0,
			"Sim": 1,
			"Curtas, na região": 2
		}

		setCurriculoVars({
			profissao: profId.id,
			arquivo: meucurr.arquivo,
			exibir_curriculo: meucurr.exibir_curriculo,
			// @ts-ignore
			mudancas: mudancas[meucurr.mudancas],
			texto: meucurr.texto,
			method: meucurr.method
		});
	}

	const loadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
		const formData = new FormData();
		if (e.target.files) {
			formData.append("arquivo", e.target.files[0]);
			setCurriculoVars({...curriculoVars, "arquivo": formData});
		}
	};

	const updateUserCurriculo = async() => {
		setLoading(true);
		try {
			const curriculoService = new CurriculossService();
			await curriculoService.updateCurriculo(curriculoVars);
			handleModalClose();
			alert("Currículo atualizado com sucesso!");
		} catch (error) {
			alert("Houve um erro ao atualizar o currículo. Tente novamente.");
		}
		setLoading(false);
	}

	useEffect(() => {
		getProfissoesAndCurriculo();
	}, []);

	// useEffect(() => {
	// 	console.log(curriculoVars)
	// }, [curriculoVars])

	return (
		<>
			<Box display='flex' alignItems='center' justifyContent='center'>
				<span className='logoutPill' onClick={handleModalOpen}>
					Meu Currículo
				</span>
			</Box>
			<ModalBase loading={loading} title={`Alterar Senha`} open={modalOpen} handleClose={handleModalClose} saveAction={updateUserCurriculo}>
				<Box display='flex' alignItems='center' padding='50px 100px'>
					<Box width='100%'>
						<form noValidate autoComplete='off' onSubmit={handleUpdateStudentProfile}>
							<Box display='grid' gridGap='20px' gridTemplateColumns={{ sm: "1fr", md: "1fr 1fr 1fr" }} marginTop='16px'>
							<div>
								<InputLabel id='prof'>Profissão</InputLabel>
								<Select id='profissao' label='prof' fullWidth onChange={(e) => handleCurriculoVars("profissao", e.target.value)} value={curriculoVars.profissao}>
										{profissoes.map((profissao:IProfissaoCurriculo) => (
											<MenuItem key={profissao.id} value={profissao.id}>
												{profissao.nome}
											</MenuItem>
										))}
								</Select>
								</div>
								<div>
								<InputLabel id='mud'>Disponível para mudanças?</InputLabel>
								
								<Select id='mudancas' label='mud' fullWidth onChange={(e) => handleCurriculoVars("mudancas", e.target.value)} value={curriculoVars.mudancas}>
									<MenuItem value={0}>Não</MenuItem>
									<MenuItem value={1}>Sim</MenuItem>
									<MenuItem value={2}>Curtas, na região</MenuItem>
								</Select></div>
								<div>
								<InputLabel id='exibir_curr'>Exibir currículo?</InputLabel>
								<Select id='exibir_curr' label='exibir_curr' fullWidth onChange={(e) => handleCurriculoVars("exibir_curriculo", e.target.value)} value={curriculoVars.exibir_curriculo ? 1 : 0}>
									<MenuItem value={1}>Sim</MenuItem>
									<MenuItem value={0}>Não</MenuItem>
								</Select>
								</div>
							</Box>
							<Box
								marginTop={2}
							>
							<TextField
								margin='dense'
								id='presentation'
								fullWidth
								label='Apresentação Pessoal'
								value={curriculoVars.texto}
								onChange={(e) => handleCurriculoVars("texto", e.target.value)}
							/>
							</Box>
							<Box display='grid' gridTemplateColumns='1fr' gridTemplateRows='2fr' gridRowGap='20px' gridColumnGap='20px' marginTop={2}>
								<div>
								<InputLabel id='avatar'>Currículo completo em PDF</InputLabel>
								<input type='file' name='avatar' id='avatar' onChange={(e) => loadPhoto(e)} />
								</div>
							</Box>
						</form>
					</Box>
				</Box>
			</ModalBase>
		</>
	);
};

// UpdateUserProfileWrapper.defaultProps = {
// };

export { UpdateUserCurriculumWrapper as UpdateUserCurriculum };
